import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactThanksPage = () => (
    <Layout>
        <SEO title="Contact" />
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="text-center bd-callout">
                        <h2>Thank you!</h2>
                        <p>We will be in touch shortly to discuss your enquiry.</p>
                        <Link to="/" className="btn">Go to Homepage</Link>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export default ContactThanksPage;